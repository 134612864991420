import React, { useEffect, useRef } from "react";
import "./Card.scss";

const useCombinedRefs = (...refs) => {
  const targetRef = useRef();
  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);
  return targetRef;
};

const Card = React.forwardRef(({ title, text }, ref) => {
  const innerRef = useRef(null);
  const cardRef = useCombinedRefs(ref, innerRef);

  useEffect(() => {
    let card = cardRef.current;
    const hoverEffect = (e) => {
      let { height, width } = card.getBoundingClientRect();
      let [mouseX, mouseY] = [e.offsetX - width / 2, -e.offsetY + height / 2];
      card.style.transform = `perspective(1500px) translateZ(100px) rotateX(${
        mouseY / 48.4
      }deg) rotateY(${mouseX / 32.4}deg)`;
    };
    card.addEventListener("mousemove", hoverEffect);
    return () => {
      card.removeEventListener("mousemove", hoverEffect);
    };
  }, [cardRef]);
  return (
    <div className="card" ref={cardRef}>
      <h1 className="card__title">{title}</h1>
      <p className="card__text">{text}</p>
    </div>
  );
});

export default Card;
