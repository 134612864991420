import React from "react";
import "./App.scss";
import Footer from "./components/Footer";
import Landing from "./components/Landing";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
// import WhyUs from "./components/WhyUs";
import Expertise from "./components/Expertise";
import About from "./components/About";
import WhatWeDo from "./components/WhatWeDo";
import ContactUs from "./components/ContactUs";
import HowWeWork from "./components/HowWeWork";
import OurValues from "./components/OurValues";

const App = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Landing />
      <About />
      <WhatWeDo />
      <Services />
      <OurValues />
      <Expertise />
      <HowWeWork />
      {/* <WhyUs /> */}
      <Footer />
      <ContactUs />
    </React.Fragment>
  );
};

export default App;
