import React, { useEffect, useRef } from "react";
import "./HowWeWork.scss";
import Article from "./Article";

const HowWeWork = () => {
  const title = useRef(null);
  const borderBottom = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      const { top: titleTop } = title.current.getBoundingClientRect();
      const { top: borderTop } = borderBottom.current.getBoundingClientRect();
      if (titleTop + 50 <= window.innerHeight) {
        title.current.style.opacity = 1;
        title.current.style.left = 0;
      }
      if (borderTop + 50 <= window.innerHeight) {
        borderBottom.current.style.width = "100%";
        window.removeEventListener("scroll", checkScroll);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  return (
    <div className="how-we-work" id="how-we-work">
      <div className="how-we-work__heading" ref={title}>
        How we work
        <div className="borderBottom" ref={borderBottom}></div>
      </div>
      <div className="how-we-work__content">
        <Article
          heading="Agile approach"
          description="Once we’ve heard more about your goals and timeline, we’ll start assembling your team"
          index="1"
        />
        <Article
          heading="Proper engagement "
          description="We’ll send you pre-screened, high-quality candidates for consideration, ensuring a perfect fit for your company"
          index="2"
        />
        <Article
          heading="Detailed report"
          description="Your new development team will work tirelessly to deliver on your product vision"
          index="3"
        />
        <Article
          heading="Dedicated help"
          description="Following release, we’ll easily scale your team to match your product’s success"
          index="4"
        />
      </div>
    </div>
  );
};

export default HowWeWork;
