import React, { useRef, useEffect } from "react";
import "./WhatWeDo.scss";
import GridItem from "./GridItem";
import {
  faUserPlus,
  faMobile,
  faPencilRuler,
  faTools,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { faHtml5 } from "@fortawesome/free-brands-svg-icons";
const data = [
  {
    heading: "Hire",
    text: "Hire the top Developers, Project Managers or even an Entire Team! Whatever your needs, we'll fulfill them.",
    icon: faUserPlus,
  },
  {
    heading: "Mobile App Developement",
    text: "We can assist you amplify your business productivity with phenomenal mobile app solutions",
    icon: faMobile,
  },
  {
    heading: "Web Application Developement",
    text: "Single Page Websites to Full Stack web applications, we can create whatever you want.",
    icon: faHtml5,
  },
  {
    heading: "UI/UX Developement",
    text: "Good UX design is all about putting the user first. Any aspect of a website, app or software that doesn’t consider the user’s needs is ultimately doomed to fail. With our proficient developers, you'll always stay ahead of the design curve.",
    icon: faPencilRuler,
  },
  {
    heading: "Quality Analysis And Testing",
    text: "No one likes Bugs, thats why we ensure that your product does not have any!",
    icon: faTools,
  },
  {
    heading: "Support & Maintainance",
    text: "Application support and maintenance services are aimed to ensure that applications are highly available, reliable, and relevant to evolving business needs. Since 2007, We deliver full-range support and maintenance services for applications of different types and complexity.",
    icon: faToolbox,
  },
];
const WhatWeDo = () => {
  const title = useRef(null);
  const borderBottom = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      let { top: titleTop } = title.current.getBoundingClientRect();
      let { top: borderBottomTop } =
        borderBottom.current.getBoundingClientRect();
      if (titleTop + 50 <= window.innerHeight) {
        title.current.style.opacity = 1;
        title.current.style.left = 0;
      }
      if (borderBottomTop + 50 <= window.innerHeight) {
        borderBottom.current.style.width = "100%";
      }
      if (
        borderBottomTop + 50 <= window.innerHeight &&
        titleTop + 50 <= window.innerHeight
      )
        window.removeEventListener("scroll", checkScroll);
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  return (
    <div className="whatWeDo" id="whatWeDo">
      <div className="whatWeDo__title" ref={title}>
        What We Do
        <div className="borderBottom" ref={borderBottom}></div>
      </div>
      <div className="whatWeDo__grid">
        {data.map(({ heading, text, icon }, index) => (
          <GridItem
            heading={heading}
            text={text}
            icon={icon}
            delay={0.1 * index}
            key={heading}
          />
        ))}
      </div>
    </div>
  );
};

export default WhatWeDo;
