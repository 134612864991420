import React, { useRef, useEffect } from "react";
import "./Article.scss";

const Article = ({ heading, description, index }) => {
  const article = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      const { top: articleTop } = article.current.getBoundingClientRect();
      if (articleTop - 50 <= window.innerHeight) {
        article.current.style.opacity = 1;
        article.current.style.top = 0;
        window.removeEventListener("scroll", checkScroll);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  return (
    <div className="article" ref={article}>
      <h3 className="article__heading">{heading}</h3>
      <p className="article__description">{description}</p>
      <div className="article__index">{index}</div>
    </div>
  );
};

export default Article;
