import React, { useRef, useEffect } from "react";
import "./GridItem.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const GridItem = ({ heading, text, icon, delay }) => {
  const item = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      let { top: itemTop } = item.current.getBoundingClientRect();
      if (itemTop + 50 <= window.innerHeight) {
        item.current.style.transition = `all .5s ease ${delay}s`;
        item.current.style.opacity = 1;
        item.current.style.top = 0;
        setTimeout(() => {
          item.current.style.transition = `all .2s ease`;
        }, 500 + delay * 1000);
        window.removeEventListener("scroll", checkScroll);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [delay]);
  return (
    <div className="gridItem" ref={item}>
      <div className="gridItem__heading">
        <FontAwesomeIcon icon={icon} />
        {heading}
      </div>
      <div className="gridItem__text">{text}</div>
    </div>
  );
};

export default GridItem;
