import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import "./ContactUs.scss";
export default function ContactUs() {
  const contact = useRef(null);
  const socials = useRef(null);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    const checkScroll = () => {
      let coordinates = contact.current.getBoundingClientRect();
      const { top: socialsTop } = socials.current.getBoundingClientRect();
      if (coordinates.top + 50 <= window.innerHeight) {
        contact.current.style.clipPath = "circle(100vmax at 20% 20%)";
      }
      if (socialsTop <= window.innerHeight) {
        socials.current.style.top = 0;
        socials.current.style.opacity = 1;
        window.removeEventListener("scroll", checkScroll);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  /* eslint-disable */
  return (
		<div className='ContactUs'>
			<div className='contact' ref={contact}>
				<div className='contact__form'>
					<form action='#' className='form'>
						<div className='u-margin-bottom-medium'>
							<h2 className='heading-secondary'>Contact Us</h2>
						</div>
						<div className='form__group'>
							<input
								type='text'
								name='name'
								id='name'
								className='form__input'
								placeholder='Full Name'
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
							<label htmlFor='name' className='form__label'>
								Full Name
							</label>
						</div>
						<div className='form__group'>
							<input
								type='text'
								name='phone'
								id='phone'
								className='form__input'
								placeholder='Phone Number'
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								required
							/>
							<label htmlFor='phone' className='form__label'>
								Phone Number
							</label>
						</div>
						<div className='form__group'>
							<input
								type='email'
								name='email'
								id='email'
								className='form__input'
								placeholder='Email Address'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
							<label htmlFor='email' className='form__label'>
								Email Address
							</label>
						</div>
						<div className='form__group'>
							<textarea
								name='message'
								className='form__input'
								id='message'
								rows='3'
								placeholder='Message'
								style={{ resize: 'vertical' }}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
							></textarea>
							<label htmlFor='message' className='form__label'>
								Message
							</label>
						</div>
						<a
							href={`mailto:hr@pplwork.com?subject=Getting in Touch&body=Hi, I would like to get in touch. %0D%0A %0D%0A${name}%0D%0A${phone}`}
							className='contact__button'
						>
							Submit
						</a>
					</form>
				</div>
			</div>
			<div className='contact__socials' ref={socials}>
				<a href='https://www.facebook.com/Pplwork-106571921884497' target='_blank'>
					<FontAwesomeIcon icon={faFacebookSquare} />
				</a>
				<a href='https://www.linkedin.com/company/pplwork' target='_blank'>
					<FontAwesomeIcon icon={faLinkedin} />
				</a>
				<a href='https://twitter.com/PPLWORK__' target='_blank'>
					<FontAwesomeIcon icon={faTwitterSquare} />
				</a>
			</div>
		</div>
  );
}
