import React, { useEffect, useRef } from "react";
import "./About.scss";
import office from "../assets/office.jpg";
import ceo from "../assets/ceo.jpeg";
const About = () => {
  const borderBottom = useRef(null);
  const title = useRef(null);
  const text = useRef(null);
  const imageGroup = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      let { top: borderBottomTop } =
        borderBottom.current.getBoundingClientRect();
      let { top: titleTop } = title.current.getBoundingClientRect();
      let { top: textTop } = text.current.getBoundingClientRect();
      let { top: imageGroupTop } = imageGroup.current.getBoundingClientRect();
      if (borderBottomTop + 50 <= window.innerHeight) {
        borderBottom.current.style.width = "100%";
      }
      if (titleTop + 50 <= window.innerHeight) {
        title.current.style.opacity = 1;
        title.current.style.top = 0;
      }
      if (imageGroupTop + 50 <= window.innerHeight) {
        imageGroup.current.style.left = 0;
        imageGroup.current.style.opacity = 1;
      }
      if (textTop + 50 <= window.innerHeight) {
        text.current.style.top = 0;
        text.current.style.opacity = 1;
      }
      if (
        textTop + 50 <= window.innerHeight &&
        imageGroupTop + 50 <= window.innerHeight
      )
        window.removeEventListener("scroll", checkScroll);
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  return (
    <div className="about" id="about">
      <div className="wrapper">
        <div className="about__content">
          <div className="about__left">
            <div className="about__content-title" ref={title}>
              About Us
              <div className="borderBottom" ref={borderBottom}></div>
            </div>
            <div className="about__content-text" ref={text}>
              PPLwork is a software development company which is committed to
              serving the Indian and international market in providing technical
              and software solutions. We aim to undertake projects and provide
              sustainable customized solutions to clients based on their
              requirements. We are a new company with new ideas and outlook
              towards our business. With a focus on sustainable human resource
              solutions, we will have the highest levels of ethical practice in
              our business, which will ensure the highest client and employee
              satisfaction levels
            </div>
          </div>
          <div className="about__right" ref={imageGroup}>
            <img src={office} className="image__left" alt="office" />
            <img src={ceo} className="image__right" alt="ceo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
