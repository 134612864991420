import React from "react";
import "./Landing.scss";
const Text = () => {
  return (
    <div className="landingText">
      <div className="landingText__slogan">
        <div>You ask for it,</div>
        <div id="second_line">
          We
          <span className="landingText__changableText">
            <div className="landingText__changableText-helper">
              <div>Deliver</div>
              <div>Deploy</div>
              <div>Develop</div>
              <div>Design</div>
            </div>
          </span>
          it
        </div>
      </div>
    </div>
  );
};

const Landing = (props) => {
  return (
    <>
      <div className="landing" id="home">
        <Text />
      </div>
    </>
  );
};

export default Landing;
