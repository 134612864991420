import React, { useEffect, useRef } from "react";
// import "./Product.scss";
import "./Product_New.scss";
import arrow from "../assets/arrow-up-solid.svg";

const Product = ({ title, body, delay }) => {
  const product = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      const { top: productTop } = product.current.getBoundingClientRect();
      if (productTop + 50 <= window.innerHeight) {
        product.current.style.transition = `all 1s ease-in-out ${delay * 2}s`;
        product.current.style.opacity = 1;
        product.current.style.transform = "translateX(0px)";
        setTimeout(() => {
          product.current.style.transition = "all .4s ease";
        }, 1500);
        window.removeEventListener("scroll", checkScroll);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, [delay]);
  return (
    // eslint-disable-next-line
    <a className="product" ref={product}>
      <div className="product__content">
        <h3 className="product__title">{title}</h3>
        <img src={arrow} className="product__icon" alt="Arrow" />
        <p className="product__description">{body}</p>
      </div>
    </a>
  );
};

export default Product;
