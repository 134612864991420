import React, { useEffect, useRef } from "react";
import "./Expertise.scss";
import Product from "./Product";

const Expertise = () => {
  const container = useRef(null);
  const title = useRef(null);
  const text = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      const { top: containerTop } = container.current.getBoundingClientRect();
      const { top: titleTop } = title.current.getBoundingClientRect();
      const { top: textTop } = title.current.getBoundingClientRect();
      if (containerTop + 50 <= window.innerHeight) {
        container.current.style.backgroundPosition = "0%";
      }
      if (titleTop + 50 <= window.innerHeight) {
        title.current.style.opacity = 1;
        title.current.style.top = 0;
      }
      if (textTop + 50 <= window.innerHeight) {
        text.current.style.opacity = 1;
        text.current.style.top = 0;
        window.removeEventListener("scroll", checkScroll);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  });
  return (
    <div className="expertise" id="expertise" ref={container}>
      <div className="expertise__info">
        <div className="expertise__heading" ref={title}>
          Our Expertise
        </div>
        <div className="expertise__text" ref={text}>
          Whatever your product vision, our engineers possess the stack fluency
          and platform knowledge to bring it to life. Our team remains on the
          forefront of innovation, implementing solutions to suit even the most
          unique needs.
        </div>
      </div>
      <div className="expertise__content">
        <div className="expertise__content-absolute">
          <Product
            title="Information Technology"
            body="We excel in monitoring and managing computer hardware, software and networks and other resources to deliver immediate business results."
            delay={0.1}
          />
          <Product
            title="Blockchain Technology"
            body="We make use of cutting edge Distributed Ledger Technologies, including Platforms like Bitcoin, Ethereum to build secure blockchain-based solutions."
            delay={0.2}
          />
          <Product
            title="Health Sector"
            body="We empower global clients with healthcare application development teams that build cost-effective, compliant, and scalable applications and help them grow their businesses exponentially."
            delay={0.3}
          />
        </div>
      </div>
      <div className="expertise__content">
        <div className="expertise__content-absolute">
          <Product
            title="E-Commerce"
            body='We believe our "product" is pretty special. We offer strategic digital marketing expertise blended with multiple years of eCommerce & retail experience, personalised for your unique requirements'
            delay={0.1}
          />
          <Product
            title="Educational Sector"
            body="We provide completely automated, customizable, cloud-based, or on-premise education software services which integrate all your ideas & vision and provide an interactive online learning platform."
            delay={0.2}
          />
          <Product
            title="Media and Entertainment"
            body="We combine our consulting, technology, and industry expertise to solve the challenges facing media and entertainment organizations."
            delay={0.3}
          />
        </div>
      </div>
    </div>
  );
};

export default Expertise;
