import React, { useEffect, useRef } from "react";
import "./Footer.scss";

export default function Footer() {
  const heading = useRef(null);
  const text = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      const { top: headingTop } = heading.current.getBoundingClientRect();
      const { top: textTop } = text.current.getBoundingClientRect();
      if (headingTop + 50 <= window.innerHeight) {
        heading.current.style.opacity = 1;
        heading.current.style.top = 0;
      }
      if (textTop + 50 <= window.innerHeight) {
        text.current.style.opacity = 1;
        text.current.style.top = 0;
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);
  return (
    <div className="footer" id="footer">
      <h1 className="footer__heading" ref={heading}>
        Finding an agency who understands your digital needs is hard.
      </h1>
      <p className="footer__text" ref={text}>
        We provide the one stop solution to all your needs.
      </p>
    </div>
  );
}
