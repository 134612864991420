import React, { useEffect, useRef } from "react";
import "./Services.scss";
import Card from "./Card";

const data = [
  {
    title: "Code assistance",
    text: "We operate the best technology to increase your product quality, workflow efficiency and enhance your business globally.",
  },
  {
    title: "Hire Full Stack Developers",
    text: "Forget prima donna developers — our team cements their rock star status based on deliverables, not attitude. We hire team players who have their eye on a single goal: yours.",
  },
  {
    title: "Contract developers",
    text: "For every client we invest in building a cohesive team with the shared goals and sense of ownership. We have perfected a clean, economical approach for entering and exiting client development processes at the right time using the right resources. ",
  },
  {
    title: "Onsite developers",
    text: "Is remote work not for you ? We provide Onsite developers for a hands on developement workflow.",
  },
];

const Services = () => {
  const borderBottom = useRef(null);
  const heading = useRef(null);
  const subHeading = useRef(null);
  const cards = useRef({});
  const title = useRef(null);

  useEffect(() => {
    const checkScroll = () => {
      const { top: titleTop } = borderBottom.current.getBoundingClientRect();
      const { top: borderBottomTop } =
        borderBottom.current.getBoundingClientRect();
      const { top: headingTop } = heading.current.getBoundingClientRect();
      const { top: subHeadingTop } = subHeading.current.getBoundingClientRect();
      const { top: card1Top } = cards.current["1"].getBoundingClientRect();
      const { top: card2Top } = cards.current["2"].getBoundingClientRect();
      const { top: card3Top } = cards.current["3"].getBoundingClientRect();
      const { top: card4Top } = cards.current["4"].getBoundingClientRect();

      if (titleTop + 50 <= window.innerHeight) {
        title.current.style.opacity = 1;
        title.current.style.top = 0;
      }
      if (borderBottomTop + 50 <= window.innerHeight)
        borderBottom.current.style.width = "100%";
      if (headingTop + 50 <= window.innerHeight) {
        heading.current.style.top = 0;
        heading.current.style.opacity = 1;
      }
      if (subHeadingTop + 50 <= window.innerHeight) {
        subHeading.current.style.top = 0;
        subHeading.current.style.opacity = 1;
      }
      if (card1Top + 50 <= window.innerHeight) {
        cards.current["1"].style.transition = "all 1s ease";
        cards.current["1"].style.transform =
          "perspective(1500px) rotateY(0deg)";
        setTimeout(() => {
          cards.current["1"].style.transformOrigin = "center";
          cards.current["1"].style.transition = "all .2s ease";
        }, 1000);
      }
      if (card2Top + 50 <= window.innerHeight) {
        cards.current["2"].style.transition = "all 1s ease 0.2s";
        cards.current["2"].style.transform =
          "perspective(1500px) rotateY(0deg)";
        setTimeout(() => {
          cards.current["2"].style.transformOrigin = "center";
          cards.current["2"].style.transition = "all .2s ease";
        }, 1200);
      }
      if (card3Top + 50 <= window.innerHeight) {
        cards.current["3"].style.transition = "all 1s ease 0.4s";
        cards.current["3"].style.transform =
          "perspective(1500px) rotateY(0deg)";
        setTimeout(() => {
          cards.current["3"].style.transformOrigin = "center";
          cards.current["3"].style.transition = "all .2s ease";
        }, 1400);
      }
      if (card4Top + 50 <= window.innerHeight) {
        cards.current["4"].style.transition = "all 1s ease 0.6s";
        cards.current["4"].style.transform =
          "perspective(1500px) rotateY(0deg)";
        setTimeout(() => {
          cards.current["4"].style.transformOrigin = "center";
          cards.current["4"].style.transition = "all .2s ease";
        }, 1600);
      }
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  return (
    <div id="services" className="services">
      <h1 className="services__heading" ref={title}>
        Services
        <div className="borderBottom" ref={borderBottom}></div>
      </h1>
      <div className="services__subheadingContainer">
        <h2 className="services__subheading" ref={heading}>
          Fulfilling your requirements, one framework at a time
        </h2>
        <h3 className="services__subheading-subheading" ref={subHeading}>
          or maybe two, or three, you name it
        </h3>
      </div>
      <div className="cardsContainer">
        <div>
          <Card
            title={data[0].title}
            text={data[0].text}
            ref={(ref) => (cards.current["1"] = ref)}
          />
          <Card
            title={data[1].title}
            text={data[1].text}
            ref={(ref) => (cards.current["2"] = ref)}
          />
        </div>
        <div>
          <Card
            title={data[2].title}
            text={data[2].text}
            ref={(ref) => (cards.current["3"] = ref)}
          />
          <Card
            title={data[3].title}
            text={data[3].text}
            ref={(ref) => (cards.current["4"] = ref)}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
