import React, { useEffect, useRef } from "react";
// import logo from "../assets/logo.png";
import "./Navbar.scss";
const Navbar = () => {
  const navRef = useRef(null);
  const linksRef = useRef(null);
  const headingRef = useRef(null);
  const hamburgRef = useRef(null);
  const hamburgLinksRef = useRef(null);
  const logoRef = useRef(null);
  useEffect(() => {
    const navbarBG = () => {
      // when navbar starts changing color
      if (window.scrollY > 80) {
        navRef.current.style.backgroundColor = `rgba(255,255,255,${
          (window.scrollY - 80) / 100
        })`;
        hamburgLinksRef.current.style.backgroundColor = `rgba(14,14,14,${
          (window.scrollY - 80) / 100
        })`;
        navRef.current.style.boxShadow = `0 1px 9px 1px rgba(0,0,0,${Math.min(
          (window.scrollY - 80) / 100,
          0.1
        )})`;
        headingRef.current.classList.add("dark");
        linksRef.current.classList.add("dark");
        hamburgRef.current.classList.add("bgdark");
        logoRef.current.classList.add("bgdark");
      }
      // when navbar is going back to transparent
      else {
        navRef.current.style.backgroundColor = `rgba(255,255,255,${
          (window.scrollY - 80) / 100
        })`;
        hamburgLinksRef.current.style.backgroundColor = `rgba(14,14,14,${
          (window.scrollY - 80) / 100
        })`;
        navRef.current.style.boxShadow = `0 1px 9px 1px rgba(0,0,0,${Math.min(
          (window.scrollY - 80) / 100,
          0.1
        )})`;
        headingRef.current.classList.remove("dark");
        linksRef.current.classList.remove("dark");
        hamburgRef.current.classList.remove("bgdark");
        logoRef.current.classList.remove("bgdark");
      }
    };
    navbarBG();
    window.addEventListener("scroll", navbarBG);
    return () => {
      window.removeEventListener("scroll", navbarBG);
    };
  }, []);

  return (
    <div className="navbar" ref={navRef}>
      <div className="navbar__logo">
        <div className="navbar__image" ref={logoRef}></div>
        <div className="navbar__heading" ref={headingRef}>
          PPLWORK
        </div>
      </div>
      <div className="navbar__links" ref={linksRef}>
        <div>
          <a href="#home">Home</a>
        </div>
        <div>
          <a href="#about">About</a>
        </div>
        <div>
          <a href="#whatWeDo">What We Do</a>
        </div>
        <div>
          <a href="#services">Services</a>
        </div>
        <div>
          <a href="#ourValues">Our Values</a>
        </div>
        <div>
          <a href="#expertise">Expertise</a>
        </div>
        <div>
          <a href="#how-we-work">How We Work</a>
        </div>
        <div>
          <a href="#footer">Contact</a>
        </div>
      </div>
      <div className="chkboxcontainer">
        <input type="checkbox" className="checkbox" id="checkbox" />
        <label className="chkbox" htmlFor="checkbox" ref={hamburgRef} />
        <div className="hamburg__links" ref={hamburgLinksRef}>
          <div>
            <a href="#home">Home</a>
          </div>
          <div>
            <a href="#about">About</a>
          </div>
          <div>
            <a href="#whatWeDo">What We Do</a>
          </div>
          <div>
            <a href="#services">Services</a>
          </div>
          <div>
            <a href="#ourValues">Our Values</a>
          </div>
          <div>
            <a href="#expertise">Expertise</a>
          </div>
          <div>
            <a href="#how-we-work">How We Work</a>
          </div>
          <div>
            <a href="#footer">Contact</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
