import React, { useEffect, useRef } from "react";
import "./OurValues.scss";
const OurValues = () => {
  const title = useRef(null);
  const valuesContainer = useRef(null);
  useEffect(() => {
    const checkScroll = () => {
      const { top: titleTop } = title.current.getBoundingClientRect();
      if (titleTop + 50 <= window.innerHeight) {
        title.current.style.opacity = 1;
      }
      valuesContainer.current.childNodes.forEach((ele, index, arr) => {
        const { top } = ele.getBoundingClientRect();
        if (top + 50 <= window.innerHeight) {
          ele.style.opacity = 1;
          ele.style.left = 0;
          if (index === arr.length - 1)
            window.removeEventListener("scroll", checkScroll);
          setTimeout(() => {
            ele.style.transition = "all .2s ease";
          }, 1000);
        }
      });
    };
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);
  return (
    <div className="ourValues" id="ourValues">
      <div className="ourValues__content">
        <div className="ourValues__content-title" ref={title}>
          Our Values
          <div>
            Because effectiveness without values is a tool without a purpose.
          </div>
        </div>
        <div
          className="ourValues__content-valuesContainer"
          ref={valuesContainer}
        >
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">Trust</div>
            <div className="ourValues__content-value-Description">
              PPLWORk walks the walk by instilling trust in their employees
              right off the bat. We support the growth of our employees and they
              support us. We are trustworthy and act in good faith.
            </div>
          </div>
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">Engagement</div>
            <div className="ourValues__content-value-Description">
              Employees are not products, they are assets. That is why we
              maintain constant engagement with our employees to ensure that
              they feel always at home.
            </div>
          </div>
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">
              Taking ownership
            </div>
            <div className="ourValues__content-value-Description">
              Taking ownership is about taking initiative. We take ownership
              because we believe that taking action is not someone else’s
              responsibility.
            </div>
          </div>
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">Accountability</div>
            <div className="ourValues__content-value-Description">
              Being accountable means taking responsibility for an outcome.
              Where ownership is the initiative, accountability is the
              follow-through. We deliver as promised, respecting any deadlines
              or budget constraints that you may have.
            </div>
          </div>
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">
              Execution matters
            </div>
            <div className="ourValues__content-value-Description">
              We believe in the relentless pursuit of excellence in execution –
              working with urgency, innovation and passion. Excellence is always
              intentional. Our success as individuals and as an organization
              depends on how consistently we can bring this value to life.
            </div>
          </div>
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">
              Going the extra mile
            </div>
            <div className="ourValues__content-value-Description">
              Doing more than what is required, making the extra effort, every
              time.For us going the extra mile is really about delivering
              stellar results and building great relationships.
            </div>
          </div>
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">Quality</div>
            <div className="ourValues__content-value-Description">
              Quality for us means maintaining rigorous standards for all of our
              work, no matter how big or small the project. We care deeply about
              our work, about providing excellent customer service, and we truly
              care about achieving results for our clients.
            </div>
          </div>
          <div className="ourValues__content-value">
            <div className="ourValues__content-value-Title">Transparency</div>
            <div className="ourValues__content-value-Description">
              We are authentic in the way we message ourselves externally—to
              stakeholders, to prospective customers and talent. Our leadership
              team strives to clearly and effectively communicate messages
              across the organization. We respect one another enough to confront
              issues and problems as they arise in order to push the work
              product and team to the next level.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurValues;
